/* eslint-disable no-unused-vars */
import { createPopper } from '@popperjs/core'
import * as bootstrap from 'bootstrap'

console.log("It's working!")

document.addEventListener('DOMContentLoaded', (event) => {
  console.log('DOMContentLoaded', event)

  initApp()
})

// eslint-disable-next-line space-before-function-paren
function initApp() {
  console.log('initApp')

  initPopOvers()

  // initForm()
}

// eslint-disable-next-line space-before-function-paren
function initPopOvers() {
  console.log('initPopOvers')
  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  )
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
}

// // eslint-disable-next-line space-before-function-paren
// function initForm() {
//   console.log('initForm')

//   const contactForm = document.getElementById('contact-form')
//   contactForm.addEventListener('click', function (event) {
//     console.log('click')
//     event.preventDefault()
//     // eslint-disable-next-line no-undef
//     const request = new XMLHttpRequest()
//     const url = 'https://preview.hmh-travel.de/formhandler'
//     request.open('POST', url, true)
//     request.setRequestHeader('Content-Type', 'application/json')

//     request.onreadystatechange = function () {
//       console.log('onreadystatechange')
//       if (request.readyState === 4 && request.status === 200) {
//         console.log(
//           'readyState: ',
//           request.readyState,
//           'status: ',
//           request.status
//         )
//         const jsonData = JSON.parse(request.response)
//         console.log(jsonData)
//       }
//     }

//     const vorname = document.getElementById('vorname').value
//     const nachname = document.getElementById('nachname').value
//     const email = document.getElementById('email').value

//     const data = JSON.stringify({
//       vorname: vorname,
//       nachname: nachname,
//       email: email
//     })

//     request.send(data)
//     request.onload = function () {
//       console.log(request.responseText)
//     }
//   })
// }
